import React from "react"
import { Link } from "gatsby"

import "./posts.scss"

const Posts = ({ posts }) => (
  <ol className="blog-posts">
    {posts.map(post => {
      return (
        <li key={post.frontmatter.title} className="blog-post">
          <article itemScope itemType="http://schema.org/Article">
            <header>
              <h3>
                <Link to={post.fields.slug} itemProp="url">
                  <span itemProp="headline">{post.frontmatter.title}</span>
                </Link>
              </h3>
              <small>{post.frontmatter.date} by {post.frontmatter.authors.map(({ name, fields }) => <Link key={name} to={fields.slug}>{name}</Link>).reduce((previous, current) => Array.isArray(previous) ? [...previous, ", ", current] : [previous, ", ", current])}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: post.frontmatter.description || post.excerpt,
                }}
                itemProp="description"
              />
            </section>
            <footer>
              <small>
                <Link to={post.fields.slug}>Read more</Link>
              </small>
            </footer>
          </article>
        </li>
      )
    })}
  </ol>
)

export default Posts
